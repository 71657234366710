import React from "react"
import CategoryView from "../../views/category-view"
import { graphql } from 'gatsby'

const DesignCategory = (props) => {
  const projects = props.data.design.nodes
  const imgData = props.data.images.nodes
  const location = props.location
  const category = props.data.category
  const categories = props.data.categories && props.data.categories.nodes

  return <CategoryView
    projects={projects}
    location={location}
    images={imgData}
    category={category}
    categories={categories} />
}

export default DesignCategory


export const query = graphql`
  query($category: String) {
    design: allDesign(filter: {category: {eq: $category}}){
      nodes {
        title
        slug
        category
        images {
          alt
          alt_over
        }
      }
    }
    images: allFile(filter: {sourceInstanceName: {eq: $category}, 
      name: {regex: "/^([0-9 a-z A-Z]*)(_*)([^0-9]*)$/" }}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            outputPixelDensities: [0.25, 0.5, 0.75, 1, 2]
            width: 300
          )
        }
        relativePath
      }
    }
    category: categories(slug: {eq: $category}, type: {eq: "design"}) {
      _id
      slug
      name
      description
      type
      meta
      body
      files { 
        url
        text1
        text2
      }
    }
    categories: allCategories {
      nodes {
        _id
        slug
        name
        description
        type
        meta
        body
        files {
          url
          text1
          text2
        }
      }
    }
  }
`